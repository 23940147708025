<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteSavedTrip"
    />

    <b-card-code>
      <b-row>
        <b-col cols="12" md="6">
          <label>Trip Type</label>
          <v-select
            v-model="type"
            placeholder="Select Trip Type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tripOptions"
            class="w-100"
            :reduce="val => val.id"
            :clearable="true"
          />
        </b-col>

        <b-col v-if="checkLoginRole() === RoleEnum.SuperAdmin" cols="12" md="6">
          <label>Admin</label>
          <v-select
            v-model="user_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            placeholder="Select Admin"
            :options="adminOptions"
            :clearable="true"
          />
        </b-col>
      </b-row>
    </b-card-code>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="6" lg="6" sm="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
      <b-col cols="12" md="12">
        <div v-if="!items.length && !contentLoading" class="demo-spacing-0">
          <b-alert variant="primary" show>
            <div class="alert-body">
              <span><strong>{{ NotFound.NOT_FOUND }}</strong></span>
            </div>
          </b-alert>
        </div>
        <Loader v-if="contentLoading" />

        <app-collapse
          v-for="(item, index) in items"
          :id="item.id"
          :key="index"
          accordion
          :type="collapseType"
        >
          <app-collapse-item
            :is-visible="true"
            :title="`${TripType(item.type)} | ${item.name.toUpperCase()}`"
          >
            <b-row v-if="item.type !== 2">
              <b-col cols="12" md="3">
                <img
                  style="width:100%"
                  :src="item.moduleInfo1.main_image"
                  alt="Broken Image"
                  class="mw-100"
                >
              </b-col>
              <b-col cols="12" md="5">
                <div class="trip_detail_box">
                  <h3>
                    {{ item.moduleInfo1.hotel_name }} |
                    {{ item.moduleInfo1.title }}
                  </h3>
                  <p>
                    {{ item.moduleInfo1.full_address
                    }}{{
                      item.moduleInfo1.city ? `, ${item.moduleInfo1.city}` : ""
                    }}{{
                      item.moduleInfo1.country
                        ? `, ${item.moduleInfo1.country}`
                        : ""
                    }}
                  </p>
                  <p>{{ item.moduleInfo1.distance }} km from center</p>
                  <div>
                    <span
                      v-for="(amenity, index) of item.moduleInfo1.amenities"
                      :key="index"
                      style="font-size:10px"
                      class="mr-1"
                    >
                      <b-badge variant="light-secondary">
                        {{ amenity }}
                      </b-badge>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="trip_action text-right">
                  <b-dropdown
                    v-if="!item.isOfferPublished && !item.isOfferSaved"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'add-offer', params: { id: item.id } }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Create Offer</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class=".deleteModal"
                      @click="openModal(item.id, item.name)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="trip_date_details">
                  <p class="d-flex flex-column" />
                  <p>
                    From : {{ item.payload.checkin_date | formatDateToString }}
                  </p>
                  <p>
                    To : {{ item.payload.checkout_date | formatDateToString }}
                  </p>
                  <div class="trip_duration">
                    <span>
                      {{
                        item.total_adults > 0
                          ? `${item.total_adults} ${
                            item.total_adults > 1 ? " adults" : " adult"
                          }`
                          : ``
                      }}
                      {{
                        item.total_children > 0
                          ? `+ ${item.total_children} ${
                            item.total_children > 1 ? " children" : " child"
                          }`
                          : ``
                      }}
                      {{
                        item.total_infants > 0
                          ? `+ ${item.total_infants} ${
                            item.total_infants > 1 ? " Infants" : "Infant"
                          }` : ``
                      }}
                    </span>
                    <span>{{
                      item.nights > 1
                        ? item.nights + " nights"
                        : item.nights + " night"
                    }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="item.type === 3" class="p-1">
              <FlightCard :data="item.moduleInfo2" />
            </b-row>
            <b-row v-if="item.type === 2" class="px-1">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex">
                  <h4><strong class="py-1">{{ flightTitle(item) }}</strong></h4>
                  <h5>
                    <div class="trip_duration px-1">
                      <span>
                        {{
                          item.total_adults > 0
                            ? `${item.total_adults} ${
                              item.total_adults > 1 ? " adults" : " adult"}`
                            : ``
                        }}
                        {{
                          item.total_children > 0
                            ? `, ${item.total_children} ${
                              item.total_children > 1 ? " children" : " child"}`
                            : ``
                        }}
                        {{
                          item.total_infants > 0
                            ? `, ${item.total_infants} ${
                              item.total_infants > 1 ? " Infants" : "Infant"}` : ``
                        }}
                      </span>
                    </div>
                  </h5>
                </div>
                <div class="trip_action text-right">
                  <b-dropdown
                    v-if="!item.isOfferPublished && !item.isOfferSaved"
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'add-offer', params: { id: item.id } }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Create Offer</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class=".deleteModal"
                      @click="openModal(item.id, item.name)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <FlightCard :data="item.moduleInfo1" />
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <div class="mx-2 mb-2 mt-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing {{ totalSavedTrips >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalSavedTrips ? totalSavedTrips : endIndex }} of
              {{ totalSavedTrips }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalSavedTrips"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      </b-col>

      
    </b-row>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAlert,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BPagination,
} from "bootstrap-vue";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import savedTripsService from "@/services/saved-trips/savedTrips.service";
import errorResponseHandler from "@/services/errorHandler";
import {
  hotelFlightOptions,
  resConditionCheck,
  perPage,
  currentPage,
  perPageOptions,
  deleteConfirmMsg,
  NotFound,
  RoleEnum,
  TripType
} from "@/helpers/constant";
import ConfirmDelete from "@/views/confirm-delete/ConfirmDelete.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import getDateDiff from "@/helpers/dateDiff";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import FlightCard from "./FlighCard.vue";

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    vSelect,
    AppCollapseItem,
    AppCollapse,
    BDropdown,
    BDropdownItem,
    ConfirmDelete,
    BAlert,
    Loader,
    BBadge,
    FlightCard,
    BCard,
    BCardHeader,
    BCardBody,
    BPagination,
  },

  data() {
    return {
      contentLoading: true,
      tripType: "",
      adminOptions: [],
      hotelFlightOptions,
      collapseType: "margin",
      isVisible: true,
      currentPage,
      perPage,
      perPageOptions,
      sortBy: "",
      items: [],
      tripOptions: [],
      offerId: "",
      titleMsg: "",
      deleteMsg: "",
      user_id: "",
      type: "",
      totalChildren: "",
      NotFound,
      RoleEnum,
      checkLoginRole,
      // eslint-disable-next-line global-require
      beontrips_logo: require("@/assets/images/logo/beontrips.png"),
      TripType,
      totalSavedTrips: 0,
      startIndex: 0,
      endIndex: 0,
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getSavedTripListing();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.getSavedTripListing();
      }
    },

    user_id: {
      handler() {
        this.currentPage = 1;
        this.getSavedTripListing();
      }
    },
    type: {
      handler() {
        this.currentPage = 1;
        this.getSavedTripListing();
      }
    }
  },

  mounted() {
    this.getAdminDropDown();
    this.getSavedTripListing();
    this.getTripType();
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    async getAdminDropDown() {
      try {
        const res = await savedTripsService.getAdminDropDown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.adminOptions = res.data.data;
          this.adminOptions = this.adminOptions.map(e => ({
            label: `${e.firstName} ${e.lastName}`,
            id: e.userId
          }));
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        console.error("Error", error)
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: errorData,
        //     icon: "X-CircleIcon",
        //     variant: "danger"
        //   }
        // });
      }
    },

    openModal(id, name) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${name} offer?`;
      this.offerId = id;
      this.$refs.modal.show();
    },

    deleteSavedTrip(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.offerId) {
        savedTripsService
          .deleteSavedTrip(this.offerId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(item => item.id !== this.offerId);
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getSavedTripListing();
              } else {
                this.getSavedTripListing();
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    async getSavedTripListing() {
      this.contentLoading = true;
      try {
        const payload = {
          no_of_result: this.perPage,
          page: this.currentPage,
          type: this.type ? this.type : "",
          user_id: this.user_id ? this.user_id.id : ""
        };
        const res = await savedTripsService.getSavedTrips(payload);
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false;
          console.log(res.data.data)
          this.items = res.data.data.map(e => ({
            ...e,
            nights: getDateDiff(
              e.payload.checkin_date,
              e.payload.checkout_date
            ),

            total_children: e.type !== 2 ? e.payload.rooms.reduce((acc, itr) => {
              let children = acc;
              children += itr.children?.length > 0 ? itr.children?.length : "";
              return children;
            }, 0) : e.payload.passengers.children.length,
            total_adults: e.type !== 2 ? e.payload.rooms.reduce((acc, itr) => {
              let adults = acc;
              adults += itr.adults;
              return adults;
            }, 0) : e.payload.passengers.adults,
            total_infants: e.type === 2 ? e.payload.passengers.infants : 0,
          }));
          this.totalSavedTrips = res.data.count;
          this.startIndex = this.currentPage * this.perPage - this.perPage;
          this.endIndex = this.startIndex + this.perPage;
        }
      } catch (error) {
        console.error("error",error)
        const errorData = errorResponseHandler(error);
        this.contentLoading = false;
        if (error?.response?.status === 404) {
          this.items = [];
          this.totalSavedTrips = 0;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      }
    },

    async getTripType() {
      try {
        const res = await savedTripsService.getTripType();
        if (resConditionCheck(res.status) && res.data.data) {
          res.data.data.map(e => {
            if(e.isActive === true){
              this.tripOptions.push({
                ...e,
                label: e.name
              })
            };
          })
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    flightTitle(data) {
      if(data.type == 2) {
        return `${data?.payload?.trip_type == "roundtrip" ? 'Round Trip' : 'Oneway Trip'} ${data?.payload?.origin_value } + ${data?.payload.destination_value}`
      } else {
        return `${data?.payload?.destination_value}`
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.per-page-selector {
  width: 90px;
}
</style>
